<template>
  <div class="container-fluid">
    <div class="row dbWrap text-white text-center justify-content-center">
      <div class="dbBackground"></div>
      <div class="col-lg-7 pb-4 text-center align-self-end dbTop">
        <h2 class="text-white">Coming Soon!</h2>
        <p class="lead mb-1 position-relative">
          Webentwicklung und Prozessautomatisierung
        </p>
        <i class="bi bi-envelope-fill pe-3 text-info"></i
        ><span>
          <a href="mailto:service@dboeber.com" class="text-info"
            >service@dboeber.com</a
          >
        </span>
      </div>
      <div
        class="col-lg-7 p-3 p-lg-5 pt-lg-3 dboeber text-center align-self-center position-absolute top-50 start-50 translate-middle dbTop"
      >
        <h1 class="position-absolute top-50 start-50 translate-middle">
          dboeber
        </h1>
        <h1 class="position-absolute top-50 start-50 translate-middle">
          dboeber
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  components: {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.dbBackground {
  background-image: linear-gradient(
      rgba(var(--bs-secondary-rgb), 0.5) 40%,
      rgba(var(--bs-primary-rgb), 0.4) 80%
    ),
    url("../assets/earth2.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
  -o-background-size: cover;

  animation: dbAnimation_Hero 17s ease-out infinite alternate;
  inset: 0;
  position: absolute;
  height: 100vh;
}

.dbWrap {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.dbTop {
  z-index: 5;
}

@keyframes dbAnimation_Hero {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.dboeber {
  position: relative;
}

.dboeber h1 {
  color: #f08e0e;
  font-size: min(max(4rem, 12vw), 8rem);
  position: absolute;
  /* transform: translate(-50%, -50%); */
}

/* .dboeber h1:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #f08e0e;
} */

.dboeber h1:nth-child(2) {
  color: #75b9be;
  animation: animate 5s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
</style>
