import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/js/bootstrap.js";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPhp, faLaravel, faVuejs, faCss3, faHtml5, faPython, faSquareJs, faSass } from '@fortawesome/free-brands-svg-icons'

library.add(faPhp, faLaravel, faVuejs, faCss3, faHtml5, faPython, faSquareJs, faSass)

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.mount("#app");
