<template>
  <div>
    <coming-soon></coming-soon>
  </div>
</template>

<script>
import ComingSoon from "./components/ComingSoon.vue";

export default {
  name: "App",
  components: {
    ComingSoon,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/custom.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
</style>
